import React from 'react';
import PDLCardPreview from './components/PDLCardPreview';
import pages from './pages';
import FxNotificationsReports from './components/FxNotificationsReports';
import CardNotifications from './components/CardNotifications';

const overrides = {
  login: {
    page: {
      logo: () => (
        <img
          className="logo"
          src={require('../../assets/img/PDL_Logo.png')}
          alt="pdl-logo"
        />
      ),
      text: () => (
        <h1>
          Welcome to PDL{' '}
          <span style={{ display: 'block', textAlign: 'center' }}>
            Administration
          </span>
        </h1>
      )
    }
  },
  sidemenu: {
    logo: () => (
      <div className="dash-logo">
        <img src={require('../../assets/img/PDL_Logo.png')} alt="logo" />
      </div>
    )
  },
  card: {
    preview: (props: any) => <PDLCardPreview {...props} />,
    notifications: (props: any) => <CardNotifications {...props} />

    // details: {
    //   loadButton: (props: any) => <LoadCardButton {...props} />
    // }
  },
  user: {
    // details: {
    //   beforeContent: (props: any) => <OrderCardButton {...props} />,
    //   orderCard: (props: any) => <OrderCardButton {...props} />
    // }
  },
  kyc: {
    process: {
      actions: () => null
    }
  },
  'fx-notifications-reports': (props: any) => (
    <FxNotificationsReports user={props.user} />
  ),
  'identify-user-widget': () => null,
  pages
};

export default overrides;
